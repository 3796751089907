import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import styles from './styles/index.module.css';

const MENU_STYLE = {
  position: 'absolute',
  left: 0,
  top: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
  boxShadow: '0 1px 3px rgba(0, 0, 0, .15)',
};

const SCROLL_STYLE = {
  overflowY: 'scroll',
  height: '288px',
};

const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};

const ITEMS_VISIBLE_LENGTH = 8;

class Suggest extends Component {
  static propTypes = {
    title: PropTypes.string,
    valid: PropTypes.string,
    currentLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    suggests: PropTypes.array.isRequired,  // [{ name: '', id: 0 }]
    withScroll: PropTypes.bool,
    disabled: PropTypes.bool,
    clear: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isRequestChange: PropTypes.bool,
    isTrackingChanges: PropTypes.bool,
    tripService: PropTypes.object,
    serviceName: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    valid: '',
    serviceName: '',
    withScroll: false,
    disabled: false,
    clear: true,
    isRequestChange: false,
    isTrackingChanges: false,
    tripService: {},
    onChange: () => {},
    onBlur: () => {},
  };

  state = {
    label: this.props.currentLabel,
    suggests: this.props.suggests,
  };

  componentDidUpdate(prevProps) {
    const { currentLabel } = this.props;

    if (currentLabel !== prevProps.currentLabel) {
      this.fetchData(currentLabel);
    }

    if (!arraysEqual(this.props.suggests, prevProps.suggests)) {
      this.setSuggests(this.props.suggests);
    }
  }

  setSuggests = suggests => this.setState({
    suggests,
  });

  fetchData = currentLabel => this.setState({
    label: currentLabel,
  });

  handleRequestChange = (value) => {
    const { tripService, onChange, serviceName } = this.props;

    tripService.getProviders(value, serviceName).then((res) => {
      this.setState({
        suggests: res,
      });
    });

    onChange(value);
  }

  handleChange = (value) => {
    const { suggests } = this.state;
    const { isRequestChange, onChange, isTrackingChanges } = this.props;

    if (!value) {
      if (isRequestChange) {
        this.handleRequestChange(value);
      }

      return this.handleClear();
    }

    const preparedValue = value.toLowerCase();
    let filteredSuggest;

    if (isTrackingChanges) {
      onChange(value);
    }

    this.setState({
      label: value,
    }, () => {
      if (isRequestChange) {
        this.handleRequestChange(value);
      } else {
        filteredSuggest = suggests.filter(({ name }) => name.toLowerCase().includes(preparedValue));

        this.setState({
          suggests: filteredSuggest,
        });
      }
    });

    return null;
  };

  handleSelect = (item) => {
    const { suggests, onSelect } = this.props;

    this.setState({
      label: item.name,
      suggests,
    });

    return onSelect(item);
  };

  handleClear = () => {
    const { suggests, onSelect } = this.props;

    this.setState({
      label: '',
      suggests,
    });

    return onSelect({ name: '', id: 0 });
  };

  renderItem = ({ name, id }) => (
    <div className={ styles['autocomplete-item'] } key={ id }>
      { name }
    </div>
  );

  render() {
    const { title, valid, withScroll, disabled, clear, onBlur } = this.props;
    const { suggests, label } = this.state;

    const validHtml = !!valid.length && <span className={ styles['error-msg'] }>{ valid }</span>;
    const clearHtml = clear && !!label.length && !disabled &&
    <button onClick={ this.handleClear } className={ styles.button } disabled={ disabled }>
      <i className='material-icons'>clear</i>
    </button>;

    const menuStyle = withScroll && suggests.length >= ITEMS_VISIBLE_LENGTH ? { ...MENU_STYLE, ...SCROLL_STYLE } : MENU_STYLE;

    return (
      <div className={ `${styles['auto-input']} ${valid.length ? styles['no-valid'] : ''}` }>
        <label>{ title }</label>
        <div className={ styles.auto }>
          <Autocomplete
            menuStyle={ menuStyle }
            value={ label }
            items={ suggests }
            getItemValue={ item => item.name }
            onSelect={ (field, value) => this.handleSelect(value) }
            onChange={ (e, value) => this.handleChange(value) }
            renderItem={ item => this.renderItem(item) }
            inputProps={ { disabled, onBlur } }
          />
          { clearHtml }
        </div>
        { validHtml }
      </div>
    );
  }
}

export default Suggest;
