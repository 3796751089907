import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getMoment, isMoreThanThirtyOneDays, isAfterDate, isMonthAfter, isMoment } from '../../bi/utils/formatDate';

import SEARCH from '../../bi/constants/search';

import Search from '../Search';

import styles from './styles/index.module.scss';

const FIELD = {
  END_DATE: 'endDate',
  START_DATE: 'startDate',
};

const SearchReport = ({
  nameList,
  start,
  end,
  maxDate,
  description,
  tooltipMsg,
  labelButton,
  disabledButton,
  renderOption,
  datePickerLabels,
  onDownload,
}) => {
  const [date, setDate] = useState({ startDate: start, endDate: end });
  const [waitingResponse, setWaitingResponse] = useState(false);

  const handleChangeDate = (field, value) => {
    if (!isMoment(value)) {
      return;
    }

    return (
      (isAfterDate(value, date.endDate) && field !== FIELD.END_DATE
    ? setDate({ ...date, endDate: value, startDate: value })
    : setDate({ ...date, [field]: value }))
    );
  };

  const toggleWaitingResponse = value => setWaitingResponse(value);

  const handleDownload = () => {
    toggleWaitingResponse(true);

    return onDownload(date.startDate, date.endDate).finally(() => toggleWaitingResponse(false));
  };

  const renderEmpty = () => nameList && nameList.length && (
    <div className={ styles.content }>
      <div className='empty-list'>{ nameList }</div>
    </div>
  );

  const disabled = disabledButton &&
    (isMoreThanThirtyOneDays(date.startDate, date.endDate) || isMonthAfter(date.startDate, date.endDate));

  const descriptionHtml = description && description.length && <div className={ styles.description }>{ description }</div>;
  const optionHtml = renderOption || renderEmpty();

  return (
    <div className={ styles.main }>
      { descriptionHtml }
      <div className={ styles.header }>
        <Search
          labelButton={ labelButton }
          startDate={ date.startDate }
          endDate={ date.endDate }
          maxDate={ maxDate }
          disabled={ disabled }
          waitingResponse={ waitingResponse }
          tooltipMsg={ tooltipMsg }
          datePickerLabels={ datePickerLabels }
          onChangeDate={ handleChangeDate }
          onDownload={ handleDownload }
        />
      </div>
      { optionHtml }
    </div>
  );
};

SearchReport.propTypes = {
  nameList: PropTypes.string,
  maxDate: PropTypes.object,
  start: PropTypes.object,
  end: PropTypes.object,
  description: PropTypes.string,
  tooltipMsg: PropTypes.string,
  labelButton: PropTypes.string,
  disabledButton: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
  datePickerLabels: PropTypes.string,
  renderOption: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

SearchReport.defaultProps = {
  maxDate: null,
  start: getMoment(),
  end: getMoment(),
  nameList: '',
  description: '',
  tooltipMsg: '',
  labelButton: SEARCH.DOWNLOAD,
  renderOption: null,
  disabledButton: false,
  datePickerLabels: SEARCH.DATE,
};

export default SearchReport;
