import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import { formatDate, getMoment } from '../../bi/utils/formatDate';
import { getShortName } from '../../bi/utils/employee';
import MoneyFormat from '../../bi/utils/money';

import { REPORTLABALES, REPORTNAME, FIELDLABELS } from '../../bi/constants/reports';
import MESSAGE from '../../bi/constants/noResult';
import { DEFAULTDATE } from '../../constants/time';

import SearchReport from '../SearchReport';
import NoResult from '../NoResult';

import styles from './styles/index.module.scss';

class SupplierDocuments extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
  };

  state = {
    list: [],
    title: '',
    error: false,
  };

  componentWillUnmount() {
    this.toggleShownReport([], true);
  }

  toggleShownReport = (list, value = false) => this.setState({
    list,
    title: !list.length && !value ? 'ничего не найдено' : '',
  });

  toggleError = () => this.setState({
    error: !this.state.error,
  });

  handleDownload = (startDate, endDate) => this.props.onDownload(startDate, endDate)
    .then(list => this.toggleShownReport(list));

  handleChangeCheckbox = (field, id, value) => this.props.onSend(id, field, value)
    .then(() => {
      const list = this.state.list.map((item) => {
        if (item.TripItemId === id) {
          return {
            ...item,
            [field]: value,
          };
        }

        return item;
      });

      this.setState({ list });
    })
    .catch(this.toggleError);

  renderItemHotel = ({
    RequestDate,
    ReceiveDate,
    CreateDate,
    TripItemId,
    Contact,
    Price,
    VoucherTravellers,
    Name,
    City,
    Address,
    }, index) => {
    const employeesText = VoucherTravellers.map(item => getShortName(item)).join(', ');

    return (
      <div className={ styles.row_item } key={ `collection_${TripItemId}_${index}` }>
        <div className={ styles['col-1-14'] }>{ formatDate(CreateDate, DEFAULTDATE) }</div>
        <div className={ styles['col-1-14'] }>#{ TripItemId }</div>
        <div className={ styles['col-1-6'] }>{ Contact }</div>
        <div className={ styles['col-1-6'] }>{ Name }</div>
        <div className={ styles['col-1-14'] }>{ City }</div>
        <div className={ styles['col-1-6'] }>{ Address }</div>
        <div className={ styles['col-1-14'] }>{ employeesText }</div>
        <div className={ styles['col-1-14'] }>{ MoneyFormat.moneyWithDecimal(Price, true) }</div>
        <div className={ `${styles['col-1-14']} ${styles.checkbox}` }>
          <Checkbox
            value={ RequestDate }
            onChange={ value => this.handleChangeCheckbox(FIELDLABELS.REQUESTDATE, TripItemId, value) }
          />
        </div>
        <div className={ `${styles['col-1-14']} ${styles.checkbox}` }>
          <Checkbox
            value={ ReceiveDate }
            onChange={ value => this.handleChangeCheckbox(FIELDLABELS.RECEIVEDATE, TripItemId, value) }
          />
        </div>
      </div>
    );
  };

  renderReport = () => (
    <div className={ styles.report_wrapper }>
      <div className={ styles.row }>
        <div className={ styles['col-1-14'] }>Дата заполнения поездки</div>
        <div className={ styles['col-1-14'] }>№ заказа</div>
        <div className={ styles['col-1-6'] }>Email отеля</div>
        <div className={ styles['col-1-6'] }>Наименование отеля</div>
        <div className={ styles['col-1-14'] }>Город</div>
        <div className={ styles['col-1-6'] }>Адрес</div>
        <div className={ styles['col-1-14'] }>ФИО гостей</div>
        <div className={ styles['col-1-14'] }>Сумма заказа (без наценки)</div>
        <div className={ styles['col-1-14'] }>Запрос документов</div>
        <div className={ styles['col-1-14'] }>Документы получены</div>
      </div>
      <div className={ styles.col }>
        { this.state.list.map((item, index) => this.renderItemHotel(item, index)) }
      </div>
    </div>
  );

  renderErrorDialog = () => (
    <NoResult
      message={ MESSAGE.RESPONSE }
      onClose={ this.toggleError }
    />
  );

  render() {
    const { error, list } = this.state;

    const dialogHtml = error && this.renderErrorDialog();

    return (
      <div className={ styles.wrapper }>
        <SearchReport
          nameList={ this.state.title }
          description={ REPORTNAME.SUPPLIERDOCUMENTS }
          maxDate={ getMoment() }
          labelButton={ REPORTLABALES.TOFORM }
          onDownload={ this.handleDownload }
          renderOption={ list.length ? this.renderReport() : null }
        />
        { dialogHtml }
      </div>
    );
  }
}

export default SupplierDocuments;
