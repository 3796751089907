import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Select } from 'sw-ui';

import { RANGE_OF_COMPANIES, REPORTLABALES } from '../../bi/constants/reports';
import COMPONENTS from '../../bi/constants/components';
import SEARCH from '../../bi/constants/search';

import RangeDatePicker from '../RangeDatePicker';
import AjaxButton from '../ajaxButton';

import styles from './searchDocument.module.css';

const SearchDocumentForPrint = ({
  dateStartPickerLabels,
  dateEndPickerLabels,
  startDate,
  endDate,
  maxDate,
  waitingResponse,
  tooltipMsg,
  labelButton,
  lableSelect,
  disabled,
  showTooltip,
  onChangeDate,
  onDownload,
  onChangeSelectedValue,
  selectedValue,
  maxDateLimit,
  items,
}) => {
  const tooltipHtml = tooltipMsg && (showTooltip || disabled) && (
    <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
      <div className={ styles.tooltip }>{ tooltipMsg }</div>
    </Tooltip>
  );

  return (
    <div className={ styles.search }>
      <RangeDatePicker
        startDate={ startDate }
        endDate={ endDate }
        maxDate={ maxDate }
        StartDatelabel={ dateStartPickerLabels }
        EndDatelabel={ dateEndPickerLabels }
        onChange={ onChangeDate }
        maxDateLimit={ maxDateLimit }
      />
      <Select
        label={ lableSelect }
        className={ styles.select }
        items={ items }
        value={ selectedValue }
        onChange={ onChangeSelectedValue }
        overflow
        theme={ COMPONENTS.SELECT.THEME.LIGHT }
      />
      <div className={ `${styles.actions} sw-tooltip-wrapper` }>
        { tooltipHtml }
        <AjaxButton
          label={ labelButton }
          disabled={ disabled }
          loading={ waitingResponse }
          onClick={ onDownload }
        />
      </div>
    </div>
  );
};

SearchDocumentForPrint.propTypes = {
  lableSelect: PropTypes.string,
  dateStartPickerLabels: PropTypes.string,
  dateEndPickerLabels: PropTypes.string,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object,
  waitingResponse: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  labelButton: PropTypes.string,
  showTooltip: PropTypes.bool,
  onChangeDate: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onChangeSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.number,
  maxDateLimit: PropTypes.object,
  items: PropTypes.array,
};

SearchDocumentForPrint.defaultProps = {
  lableSelect: REPORTLABALES.RANGE_OF_COMPANIES,
  dateStartPickerLabels: SEARCH.START_DATE,
  dateEndPickerLabels: SEARCH.END_DATE,
  disabled: false,
  tooltipMsg: '',
  labelButton: SEARCH.SEND,
  showTooltip: false,
  maxDate: null,
  maxDateLimit: null,
  selectedValue: null,
  items: RANGE_OF_COMPANIES,
};

export default SearchDocumentForPrint;
