import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import RangeDatePicker from '../RangeDatePicker/index';
import AjaxButton from '../../components/ajaxButton';
import COMPONENTS from '../../bi/constants/components';
import SEARCH from '../../bi/constants/search';

import styles from './search.module.css';

const Search = ({
  dateStartPickerLabels,
  dateEndPickerLabels,
  startDate,
  endDate,
  maxDate,
  waitingResponse,
  tooltipMsg,
  labelButton,
  disabled,
  showTooltip,
  onChangeDate,
  onDownload,
  maxDateLimit,
}) => {
  const tooltipHtml = tooltipMsg && (showTooltip || disabled) && (
  <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
    <div className={ styles.tooltip }>{ tooltipMsg }</div>
  </Tooltip>
  );

  return (
    <div className={ styles.search }>
      <RangeDatePicker
        startDate={ startDate }
        endDate={ endDate }
        maxDate={ maxDate }
        StartDatelabel={ dateStartPickerLabels }
        EndDatelabel={ dateEndPickerLabels }
        onChange={ onChangeDate }
        maxDateLimit={ maxDateLimit }
      />
      <div className={ `${styles.actions} sw-tooltip-wrapper` }>
        { tooltipHtml }
        <AjaxButton
          label={ labelButton }
          disabled={ disabled }
          loading={ waitingResponse }
          onClick={ onDownload }
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  dateStartPickerLabels: PropTypes.string,
  dateEndPickerLabels: PropTypes.string,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object,
  maxDateLimit: PropTypes.object,
  waitingResponse: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  labelButton: PropTypes.string,
  showTooltip: PropTypes.bool,
  onChangeDate: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

Search.defaultProps = {
  maxDateLimit: null,
  dateStartPickerLabels: SEARCH.START_DATE,
  dateEndPickerLabels: SEARCH.END_DATE,
  disabled: false,
  tooltipMsg: '',
  labelButton: SEARCH.SEND,
  showTooltip: false,
  maxDate: null,
};

export default Search;
